import {
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { LegendPosition } from '@swimlane/ngx-charts';
import {
  fromEvent,
  Subject,
  takeUntil,
  tap,
} from 'rxjs';
import { throttleTime } from 'rxjs/operators';

import {
  AppService,
  Chart,
  Dashboard,
  DashboardRegistration,
  DashboardService,
  LineChart,
  UserRoles,
} from '../../../core';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class AdminDashboardComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject();
  dashboardData: Dashboard;
  loading = true;
  registrationChart = {
    data: [] as Array<LineChart>,
    options: {
      legend: true,
      showLabels: true,
      animations: true,
      xAxis: true,
      yAxis: true,
      showXAxisLabel: true,
      showYAxisLabel: true,
      timeline: true,
      legendPosition: LegendPosition.Below as LegendPosition,
    },
  };
  usersChart = {
    data: [] as Array<Chart>,
    options: {
      gradient: true,
      showLegend: true,
      showLabels: false,
      isDoughnut: false,
      legendPosition: LegendPosition.Below as LegendPosition,
    },
  };
  socialChart = {
    data: [] as Array<Chart>,
    options: {
      cardColor: '',
    },
  };
  tooltipDisabled = false;

  constructor(
    private appService: AppService,
    private dashboardService: DashboardService,
    private translateService: TranslateService,
  ) {
    this.appService.title = 'pages.dashboard';
    this.appService.active = 'dashboard';
  }

  ngOnInit(): void {
    this.getDashboardData();
    this.translateService.onLangChange.subscribe(()=>{
      this.setupUsersChart();
      this.setupRegistersChart();
    });
    this.setSocialChartCardColor();
    this.listenForThemeChanging();
    this.registerHideTooltipEvents(['wheel', 'touchmove']);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  hideTooltip(): void {
    this.tooltipDisabled = true;
    setTimeout(() => {
      this.tooltipDisabled = false;
    }, 20);
  }

  registerHideTooltipEvents(events: Array<string>): void {
    events.forEach((eventName) => {
      fromEvent(window, eventName).pipe(
        throttleTime(300),
        tap(event => this.hideTooltip()),
        takeUntil(this.destroy$),
      ).subscribe();
    });
  }

  getDashboardData(): void {
    this.dashboardService.getDashboardData().subscribe({
      next: (dashboard: Dashboard) => {
        this.dashboardData = dashboard;
        this.setupRegistersChart();
        this.setupUsersChart();
        this.setupSocialChart();
        this.loading = false;
      },
      error: () => this.loading = false,
    });
  }

  setupRegistersChart() {
    this.registrationChart.data = [{
      name: this.translateService.instant('charts.registrations.label'),
      series: this.dashboardData.registrations.map((l: DashboardRegistration) => {
        return {
          name: this.translateService.instant('months.' + l.month.toLowerCase()),
          value: l.count,
        };
      }),
    }];
  }

  setupUsersChart() {
    this.usersChart.data = Object.values(UserRoles).map((r: UserRoles) => {
      return {
        name: this.translateService.instant(`form.role.value.${r}`),
        value: this.dashboardData.users_count[r],
      };
    });
  }

  setupSocialChart() {
    this.socialChart.data = Object.keys(this.dashboardData.providers_count).map((key: string) => {
      return {
        name: key.charAt(0).toUpperCase() + key.slice(1),
        value: this.dashboardData.providers_count[key],
      };
    });
  }

  getCardsHeight(width: number) {
    if (width < 320) {
      return 700;
    } else if (width < 600) {
      return 350;
    } else if (width > 920) {
      return 150;
    } else {
      return 250;
    }
  }

  listenForThemeChanging() {
    let self = this;
    const observer = new MutationObserver((mutationList, observer) => {
      mutationList.forEach(function(mutation) {
        if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
          self.setSocialChartCardColor();
        }
      });
    });
    observer.observe(this.document.body, { attributes: true });
  }

  setSocialChartCardColor() {
    if (this.document.body.classList.contains('dark-theme')) {
      this.socialChart.options.cardColor = '#303030';
    } else {
      this.socialChart.options.cardColor = '#FAFAFA';
    }
  }

  protected readonly document = document;
}
