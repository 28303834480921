<form [formGroup]="form">
  <div formArrayName="array">
    @for (item of array.controls; track item; let index = $index) {
      <div class="setting-value">
        <div class="d-flex justify-content-between px-3">
          <mat-form-field appearance="fill" class="w-50">
            <input matInput [type]="type" [formControlName]="index">
            @if (array.get(index.toString())?.errors) {
              <mat-error>{{ array.get(index.toString())?.errors }}</mat-error>
            }
          </mat-form-field>
          <div>
            <button mat-icon-button color="primary" type="button"
                    [disabled]="isSubmitting"
                    class="form-button"
                    (click)="removeField(index)">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </div>
      </div>
    }
    <div class="d-flex justify-content-between align-items-center px-3">
      <button mat-icon-button color="primary"
              type="button"
              [disabled]="isSubmitting"
              class="form-button me-3"
              (click)="addField()">
        <mat-icon>add</mat-icon>
      </button>
      <button mat-flat-button color="primary" type="button"
              [disabled]="isSubmitting || form.invalid || !changed"
              (click)="save()"
              class="form-button">
        {{ 'actions.save' | translate }}
      </button>
    </div>
  </div>
</form>
