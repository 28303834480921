<app-header [showIcons]="true"></app-header>
<button mat-mini-fab color="primary" class="sidenav-toggle" (click)="toggleSidebar()">
  <mat-icon>menu</mat-icon>
</button>
<mat-drawer-container class="main-container">
  <mat-drawer #drawer
              [(opened)]="expanded"
              (opened)="toggleSidebar(true)"
              (closed)="toggleSidebar(false)"
              class="sidenav">
    <app-admin-sidebar></app-admin-sidebar>
    <div class="spacer"></div>
  </mat-drawer>

  <mat-drawer-content>
    <app-page-header></app-page-header>
    <router-outlet></router-outlet>
  </mat-drawer-content>
</mat-drawer-container>
