@if (isLoading) {
  <div>
    <div class="d-flex justify-content-center">
      <mat-spinner></mat-spinner>
    </div>
  </div>
}
@if (settings || arraySettings) {
  <div class="page-container" [ngClass]="isMobile ? 'mobile' : ''">
    <mat-accordion [multi]="false">
      @for (setting of settings; track setting.name; let i = $index) {
        <mat-expansion-panel class="mb-3" [expanded]="i === 0">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ 'settings.' + setting.name | translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <app-setting [setting]="setting"></app-setting>
        </mat-expansion-panel>
      }
      @for (setting of arraySettings; track setting.name) {
        <mat-expansion-panel class="mb-3">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ 'settings.' + setting.name | translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <app-array-setting [setting]="setting"></app-array-setting>
        </mat-expansion-panel>
      }
    </mat-accordion>
  </div>
}

