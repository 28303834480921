import {
  EventEmitter,
  Injectable,
  Input,
} from '@angular/core';
import {
  FormArray,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';

import {
  AdminArraySetting,
  AdminSetting,
  AdminSettingsService,
  AdminSettingsType,
  ValidationService,
} from '../../../core';

@Injectable()
export abstract class BaseSettingComponent {
  @Input() setting: AdminSetting | AdminArraySetting;

  isSubmitting = false;
  changed = false;
  type: string;
  defaultValidator: ValidationErrors | null;
  form: FormGroup;
  onSave: EventEmitter<boolean>;

  protected constructor(
    protected settingsService: AdminSettingsService,
    protected validationService: ValidationService
  ) {
    this.onSave = new EventEmitter();
  }

  abstract getFormValue(): object;
  abstract onError(err: any): void;
  abstract populateForm(): void;

  onFormValueChange(control: FormGroup | FormArray): void {
    let initialValue = control.value;
    control.valueChanges.subscribe(() => {
      this.changed =
        Object.keys(control.value).some(
          (key) => initialValue[key] != control.value[key]
        ) || control.value.length !== initialValue.length;
    });
    this.onSave.subscribe(() => {
      initialValue = control.value;
      this.changed = false;
    });
  }

  setFieldType(): void {
    switch (this.setting.type) {
      case AdminSettingsType.emails:
        this.type = 'email';
        this.defaultValidator = Validators.email;
        break;
      case AdminSettingsType.integer:
        this.type = 'number';
        break;
      case AdminSettingsType.string:
        this.type = 'text';
        break;
      case AdminSettingsType.boolean:
        this.type = 'boolean';
        break;
    }
  }

  save(): void {
    this.isSubmitting = true;
    this.settingsService.updateSetting(this.getFormValue()).subscribe({
      next: () => {
        this.onSave.emit(true);
        this.isSubmitting = false;
      },
      error: (err) => {
        this.onError(err);
        this.isSubmitting = false;
      },
    });
  }

  parseError(form: FormGroup | FormArray, formControlName: string) {
    return this.validationService.getErrorMessage(form, formControlName);
  }

}
