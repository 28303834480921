import {
  AfterViewInit,
  Component,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { TranslateService } from '@ngx-translate/core';
import {
  debounceTime,
  fromEvent,
  tap,
} from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import {
  AppService,
  ConfirmDialog,
  ToastsService,
  User,
  UserAvatarSize,
  UserRoles,
  UserService,
  UsersService,
  UserStatuses,
} from '../../../core';
import { AjaxDataSource } from '../../../core/services/ajax.datasource';
import {
  ConfirmDialogComponent,
  DataTableComponent,
} from '../../../shared/components';

enum UserStatusFilter {
  all = 'all',
  active = 'active',
  trashed = 'trashed',
}

@Component({
  selector: 'app-admin-users-listing',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class AdminUsersComponent extends DataTableComponent implements AfterViewInit {
  @ViewChild('queryInput') queryInput: ElementRef;

  loading = false;
  displayedColumns: Array<string> = ['avatar', 'first_name', 'last_name', 'email', 'role', 'status', 'action'];
  datasource: AjaxDataSource<User>;
  roles: string[] = Object.values(UserRoles);
  statuses: typeof UserStatuses = UserStatuses;
  statusFilter: string[] = Object.values(UserStatusFilter);
  filterStorage = '_filterAdminUsers';
  filterService = 'usersService';
  avatarSize = UserAvatarSize.SMALL;
  defaultFilters = {
    q: '',
    role: '',
    trashed: UserStatusFilter.all as UserStatusFilter,
  };

  constructor(
    private appService: AppService,
    private userService: UserService,
    private usersService: UsersService,
    private translateService: TranslateService,
    private dialog: MatDialog,
    private toastsService: ToastsService,
  ) {
    super();
    this.appService.title = 'pages.users.list';
    this.appService.active = 'users';
    this.datasource = new AjaxDataSource<User>();

    this.initFilters();
  }

  override ngAfterViewInit() {
    super.ngAfterViewInit();

    fromEvent(this.queryInput.nativeElement,'keyup')
      .pipe(debounceTime(400), distinctUntilChanged(), tap(() => this.applyFilter()))
      .subscribe();
  }

  userRole(role) {
    return this.translateService.instant(`form.role.value.${role}`);
  }

  userStatus(user) {
    return this.translateService.instant(`form.status.value.${user.status}`);
  }

  reload() {
    this.updateDataSource(this.usersService.fetchUsers(this.ajaxParams.getHttpParams()));
  }

  clearFilters() {
    this.resetFilters();
    this.applyFilter();
  }

  openConfirmModal(user: User, action: 'delete' | 'restore' = 'delete') {
    const dialogData: ConfirmDialog = {
      title: this.translateService.instant(
        `messages.${action}User.title`,
        { firstName: user.first_name, lastName: user.last_name }
      ),
      message: this.translateService.instant(`messages.${action}User.message`),
    };
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.loading = true;
        this.usersService[`${action}User`](user.id).subscribe({
          next: () => {
            this.loading = false;
            this.reload();
            this.toastsService.add(this.translateService.instant(`messages.${action}User.success`));
          },
          error: () => (this.loading = false),
        });
      }
    });
  }
}
