<div class="px-0 px-lg-5 justify-content-center">
  @if (loading) {
    <div>
      <div class="d-flex justify-content-center">
        <mat-spinner></mat-spinner>
      </div>
    </div>
  }
  @if (!loading) {
    <form [formGroup]="userId ? editForm : createForm">
      <fieldset [disabled]="isSubmitting">
        <div class="row justify-content-center">
          <div
            [ngClass]="sbOpen ? 'col-12 col-xxl-4 col-xl-5 col-lg-10 mb-3 mb-xl-0' : 'col-12 col-lg-5 col-xxl-4 col-md-9 mb-3 mb-lg-0'">
            <div class="profile-card equal-height">
              <div class="profile-card__header">
                <div class="profile-card__header__title">
                  {{ 'actions.userProfile.avatarTitle' | translate }}
                </div>
              </div>
              <div class="profile-card__body">
                <div class="d-flex justify-content-center">
                  <div class="avatar-container">
                    <img class="img-avatar" [src]="avatar" alt="">
                  </div>
                </div>
                <div class="row justify-content-center mx-0">
                  <div class="avatar-upload-container">
                    <ul class="avatar-upload-container__hint">
                      <li>{{ 'actions.userProfile.avatarFileFormats' | translate }}</li>
                      <li>{{ 'actions.userProfile.avatarFileSize' | translate }}</li>
                    </ul>
                    <div class="avatar-upload-container__upload" (click)="fileInput.click()">
                      <div class="avatar-upload-container__upload__link">
                        {{ 'actions.userProfile.avatarUpload' | translate }}
                        <mat-icon>upload</mat-icon>
                      </div>
                      <input hidden type="file"
                             [accept]="['.jpg', '.png', '.bmp', 'jpeg']"
                             #fileInput
                             (change)="uploadAvatar(fileInput.files)"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div [ngClass]="sbOpen ? 'col-12 col-xxl-5 col-xl-6 col-lg-10' : 'col-12 col-lg-7 col-xl-6 col-md-9'">
            <div class="profile-card equal-height">
              <div class="profile-card__header">
                <div class="profile-card__header__title">
                  {{ 'actions.userProfile.generalTitle' | translate }}
                </div>
              </div>
              <div class="profile-card__body">
                @if (userId) {
                  <mat-form-field appearance="fill" class="w-100">
                    <mat-label>{{ 'form.status.label' | translate }}</mat-label>
                    <mat-select matNativeControl [value]="user.status" [disabled]="true">
                      @for (status of statuses; track status) {
                        <mat-option [value]="status">{{ status | titlecase }}</mat-option>
                      }
                    </mat-select>
                  </mat-form-field>
                }
                <app-input formControlName="first_name"
                           [config]="{ id: 'first_name', label: 'firstName', class: 'w-100' }"
                           [errors]="userId ? editForm.get('first_name').errors : createForm.get('first_name').errors"
                           [required]="true"
                ></app-input>
                <app-input formControlName="last_name"
                           [config]="{ id: 'last_name', label: 'lastName', class: 'w-100' }"
                           [errors]="userId ? editForm.get('last_name').errors : createForm.get('last_name').errors"
                           [required]="true"
                ></app-input>
                <app-input formControlName="email"
                           [config]="{ id: 'email', label: 'email', type: 'email', class: 'w-100' }"
                           [errors]="userId ? editForm.get('email').errors : createForm.get('email').errors"
                           [isDisabled]="userId"
                           [required]="true"
                ></app-input>
                <mat-form-field appearance="fill" class="w-100">
                  <mat-label>{{ 'form.dateOfBirth.label' | translate }}</mat-label>
                  <input matInput [max]="maxDateOfBirth" [matDatepicker]="picker" formControlName="date_of_birth">
                  <mat-hint>{{ 'form.dateOfBirth.hint' | translate }}</mat-hint>
                  <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                  <mat-error>
                    <app-validation-error-render label="dateOfBirth"
                                                 [errors]="editForm.controls.date_of_birth?.errors">
                    </app-validation-error-render>
                  </mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill" class="w-100">
                  <mat-label>{{ 'form.role.label' | translate }}</mat-label>
                  <mat-select formControlName="role" matNativeControl required>
                    @for (role of roles; track role) {
                      <mat-option [value]="role">{{ role | titlecase }}</mat-option>
                    }
                  </mat-select>
                </mat-form-field>
                <mat-label
                  class="mx-2">{{ (userId ? 'actions.changePassword' : 'actions.setPassword') | translate }}
                </mat-label>
                <input matInput [hidden]="true">
                <mat-checkbox
                  (change)="showPasswordField()">
                </mat-checkbox>
                <div class="row mx-0">
                  @if (userId && passwordField) {
                    <span class="input-hint px-0 mx-2">{{ 'actions.passwordHint' | translate }}</span>
                  }
                  @if (!userId) {
                    <span class="input-hint px-0 mx-2" [ngClass]="!passwordField ? 'mb-3': ''"
                    >{{ (passwordField ? 'actions.passwordHint' : 'actions.noPasswordHint') | translate }}</span>
                  }
                </div>
                @if (passwordField) {
                  <app-input
                    formControlName="password"
                    [config]="{ id: 'password', label: 'password', type: hidePassword ? 'password' : 'text', class: 'w-100' }"
                    [errors]="userId ? editForm.get('password').errors : createForm.get('password').errors"
                    [buttonConfig]="{icon: passIcon}"
                    [required]="true"
                    (clickButton)="hidePassword = !hidePassword">
                  </app-input>
                }
                <div class="d-flex justify-content-end">
                  @if (userId) {
                    <button mat-flat-button color="primary" type="submit" class="submit" (click)="submitForm()"
                            [disabled]="isSubmitting || !editForm.valid || !editForm.touched">
                      {{ 'actions.save' | translate }}
                    </button>
                  }
                  @if (userId && invitableStatuses.includes(user.status)) {
                    <button mat-flat-button color="primary" type="submit" class="submit ms-2" (click)="invite()"
                            [disabled]="isSubmitting || !editForm.valid || editForm.touched">
                      {{ 'actions.invite' | translate }}
                    </button>
                  }
                  @if (!userId) {
                    <button mat-flat-button color="primary" type="submit" class="submit" (click)="submitForm()"
                            [disabled]="isSubmitting || !createForm.valid || !passwordField || !createForm.touched">
                      {{ 'actions.save' | translate }}
                    </button>
                  }
                  @if (!userId) {
                    <button mat-flat-button color="primary" type="submit" class="submit ms-2"
                            (click)="submitForm(true)"
                            [disabled]="isSubmitting || !createForm.valid || !createForm.touched">
                      {{ 'actions.saveAndInvite' | translate }}
                    </button>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </fieldset>
    </form>

    @if (userId) {
      <div class="row mx-0 justify-content-center">
        <div class="mt-2"
             [ngClass]="sbOpen ? 'col-12 col-xxl-9 col-xl-11 col-lg-10 mt-xl-3' : 'col-12 col-xl-11 col-md-9 col-lg-12 col-xxl-10 mt-lg-3'">
          <div class="col-12 mt-2">
            <div class="profile-card">
              <div class="profile-card__header mb-0">
                <div class="profile-card__header__title">
                  {{ 'pages.actionLogs' | translate }}
                </div>
              </div>
              <div class="profile-card__body pt-0">
                <div class="row justify-content-center">
                  <app-action-logs [userId]="userId"></app-action-logs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    }
  }

  <ng-template #progressBar>
    <div class="d-flex align-items-center">
      <span>0%</span>
      @if ((upload$ | async); as upload) {
        <mat-progress-bar
          [mode]="upload.state === 'PENDING' ? 'buffer' : 'determinate'"
          [value]="upload.progress"
          class="mx-2"
        >
        </mat-progress-bar>
      }
      <span>100%</span>
    </div>
  </ng-template>
</div>
