import {
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  Validators,
} from '@angular/forms';

import {
  AdminArraySetting,
  AdminSettingsService,
  ValidationService,
} from '../../../../core';
import { BaseSettingComponent } from '../base-setting.component';

@Component({
  selector: 'app-array-setting',
  templateUrl: './array-setting.component.html',
  styleUrls: ['../settings.component.scss'],
})
export class ArraySettingComponent extends BaseSettingComponent implements OnInit {
  @Input() override setting: AdminArraySetting;

  get array() {
    return this.form.get('array') as FormArray;
  }

  constructor(
    validationService: ValidationService,
    settingsService: AdminSettingsService,
    private fb: FormBuilder,
  ) {
    super(settingsService, validationService);
    this.form = this.fb.group({
      array: this.fb.array([]),
    });
  }

  ngOnInit() {
    this.setFieldType();
    this.populateForm();
    setTimeout(() =>
    {
      this.validationService.addListeners();
      this.onFormValueChange(this.array);
    }, 500);
  }

  populateForm(): void {
    this.setting.value.forEach(
      value => {
        this.addItem(value);
      }
    );
  }

  addItem(value = ''): void {
    this.array.push(this.fb.control(value, [Validators.required, Validators.email]));
  }

  addField(): void {
    this.addItem();
  }

  removeField(index: number): void {
    this.array.removeAt(index);
  }

  getFormValue(): object {
    return {
      name: this.setting.name,
      value: this.array.value,
    };
  }

  afterSave() {
    this.onFormValueChange(this.array);
  }

  override onError(err: any) {
    Object.keys(err.data).forEach(key => {
      const fieldIndex = key.replace('value.', '');
      const message = err.data[key][0].replace('.' + fieldIndex, '');
      this.array.get(fieldIndex).setErrors({ serverError: { message: message } });
    });

  }
}
