import {
  Component,
  OnInit,
} from '@angular/core';
import { FormGroup } from '@angular/forms';

import {
  AdminArraySetting,
  AdminSetting,
  AdminSettingsService,
  AdminSettingsType,
  AppService,
  ToastsService,
} from '../../../core';

@Component({
  selector: 'app-admin-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class AdminSettingsComponent implements OnInit {
  settings: Array<AdminSetting>;
  arraySettings: Array<AdminArraySetting>;
  isSubmitting = false;
  isLoading = true;
  activeFieldValue: any;
  form: FormGroup;
  updatedSettings: Record<string, any> = null;
  isMobile: boolean;

  constructor(
    private appService: AppService,
    private settingsService: AdminSettingsService,
    private toastsService: ToastsService,
  ) {
    this.appService.title = 'pages.settings';
  }

  ngOnInit(): void {
    this.appService.active = 'settings';
    this.isMobile = this.appService.isMobile;
    this.settingsService.fetchSettings().subscribe({
      next: response => {
        this.settings = response.filter(x => x.type !== AdminSettingsType.emails);
        this.arraySettings = response.filter(x => x.type === AdminSettingsType.emails);
        this.isLoading = false;
      },
      error: err => {
        this.toastsService.add(err);
        this.isLoading = false;
      },
    });
  }
}
