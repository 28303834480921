import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  ActivatedRoute,
  Router,
} from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import {
  EMPTY,
  Observable,
  shareReplay,
  Subscription,
} from 'rxjs';

import {
  ApiService,
  AppService,
  HelperPrepareParamsService,
  RequestIds,
  ToastsService,
  Upload,
  User,
  UserRoles,
  UserService,
  UsersService,
  UserStatuses,
} from '../../../../core';
import { HidePassword } from '../../../../core/models/icon.model';
import { ErrorsState } from '../../../../shared';

@Component({
  selector: 'app-admin-user-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss', '../../../../shared/components/user-profile/user-profile.component.scss'],
})
export class AdminUserViewComponent implements OnInit, AfterContentChecked {
  @ViewChild('progressBar') progressBar: TemplateRef<any>;
  userId: any;
  user: User;
  loading = true;
  editForm: FormGroup;
  createForm: FormGroup;
  avatarForm: FormGroup;
  fieldRequiredMessage = 'Ths field cannot be left empty';
  isSubmitting = false;
  uploadedAvatar: File;
  upload$: Observable<Upload> = EMPTY;
  maxDateOfBirth: Date;
  roles: string[] = Object.values(UserRoles);
  statuses: string[] = Object.values(UserStatuses);
  passwordField = false;
  invitableStatuses = [UserStatuses.INVITED, UserStatuses.CREATED];
  sideBarStateSubscription: Subscription;
  sbOpen: boolean;
  preview: any;
  fileId: number;
  hidePassword = true;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private usersService: UsersService,
    private userService: UserService,
    private appService: AppService,
    private apiService: ApiService,
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    private translateService: TranslateService,
    private toastsService: ToastsService,
    private cdr: ChangeDetectorRef,
    private store: Store,
  ) {
    this.editForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      date_of_birth: [null],
      role: [UserRoles.USER, [Validators.required]],
    });
    this.createForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      date_of_birth: [null],
      role: [UserRoles.USER, [Validators.required]],
    });
    this.avatarForm = this.fb.group(
      {
        avatar: [''],
      }
    );
    this.maxDateOfBirth = new Date();
  }

  get avatar () {
    const plugAvatar = '../../../../assets/icons/person.svg';
    if (!this.userId) {
      return this.preview ?? plugAvatar;
    }
    return this.user?.avatar ? this.user.avatar.presets['500x500'] : plugAvatar;
  }

  get passIcon() {
    return this.hidePassword ? HidePassword.OFF : HidePassword.ON;
  }

  ngOnInit(): void {
    this.sbOpen = this.appService.expanded;
    this.sideBarStateSubscription = this.appService.sideBarExpandedChange.subscribe(expanded => {
      this.sbOpen = expanded;
    });
    this.userId = this.route.snapshot.paramMap.get('id');
    if (this.userId) {
      this.usersService.getUser(this.userId).subscribe({
        next: user => {
          this.user = user;
          this.appService.title = this.translateService.instant('pages.users.show', {
            firstName: user.first_name,
            lastName: user.last_name,
          });
          this.editForm.patchValue(this.user);
          this.loading = false;
        },
        error: () => {
          this.router.navigateByUrl('/users');
        },
      });
    } else {
      this.appService.title = 'pages.createUser';
      this.loading = false;
    }
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  invite(): void {
    if ((this.userId && !this.editForm.valid) || (!this.userId && !this.createForm.valid)) {
      return;
    }
    this.isSubmitting = true;
    this.usersService.invite(this.userId).subscribe({
      next: () => {
        this.isSubmitting = false;
      },
      error: () => {
        this.isSubmitting = false;
      },
    });
  }

  submitForm(isInvite: boolean = false) {
    if ((this.userId && !this.editForm.valid) || (!this.userId && !this.createForm.valid)) {
      return;
    }
    this.isSubmitting = true;
    const dateFields = ['date_of_birth'];
    const params = this.userId
      ? HelperPrepareParamsService.prepareParams(this.editForm.value, dateFields, true)
      : HelperPrepareParamsService.prepareParams(this.createForm.value, dateFields, true);
    if (!this.userId) {
      params['status'] = isInvite ? UserStatuses.INVITED : UserStatuses.CREATED;
    }
    const service = this.userId ? this.usersService.updateUser(this.userId, params) : this.usersService.createUser(params);
    service.subscribe({
      next: user => {
        this.user = user;
        if (!this.userId) {
          if (this.fileId) {
            this.updateAvatar(this.fileId, user.id);
          } else {
            this.router.navigateByUrl('/users/' + user.id);
          }
        }
        this.isSubmitting = false;
        this.editForm.markAsUntouched();
      },
      error: () => {
        const form = this.userId ? this.editForm : this.createForm;
        const requestId = this.userId ? RequestIds.USER_UPDATE : RequestIds.USER_CREATE;
        const errors = this.store.selectSnapshot(ErrorsState.errors(requestId));
        for (const key in errors) {
          form.controls[key]?.setErrors({ invalid: true, message: errors[key] });
        }
        this.isSubmitting = false;
      },
    });
  }

  uploadAvatar(files: FileList | null): void {
    if (!files || this.isSubmitting){
      return;
    }
    const file = files.item(0);
    if (file.size > this.apiService.ALLOWED_IMAGE_SIZE) {
      this.toastsService.add(this.translateService.instant('validations.avatar.size'));
      return;
    }
    if (!this.apiService.ALLOWED_IMAGE_TYPES.includes(file.type)) {
      this.toastsService.add(this.translateService.instant('validations.avatar.type'));
      return;
    }
    if (this.userId) {
      this.sendAvatar(file);
      return;
    }
    const reader = new FileReader();
    reader.onload = e => this.preview = e.target.result;
    reader.readAsDataURL(file);
    this.uploadedAvatar = file;
    this.sendAvatar(file);
  }

  sendAvatar(file?: File) {
    if (!file) {
      return;
    }
    this.upload$ = this.apiService.upload(file).pipe(shareReplay());
    this.isSubmitting = true;
    this.openProgressBar();
    this.upload$
      .subscribe({
        next: fileResponse => {
          if (fileResponse.state === 'DONE') {
            this.dismissProgressBar();
            this.isSubmitting = false;
            this.fileId = fileResponse.data.id;
            if (this.userId) {
              this.updateAvatar(fileResponse.data.id, this.userId);
            }
          }
        },
        error: () => {
          this.dismissProgressBar();
          this.isSubmitting = false;
        },
      });
  }

  updateAvatar(fileId?: number, id?: number) {
    if (!fileId) {
      return;
    }
    this.isSubmitting = true;
    this.avatarForm.get('avatar').setValue({ id: fileId });
    this.usersService.updateUser(id, this.avatarForm.value).subscribe({
      next: user => {
        this.isSubmitting = false;
        this.user = user;
        if (!this.userId) {
          this.router.navigateByUrl('/users/' + user.id);
        }
      },
      error: error => {
        this.isSubmitting = false;
        this.openSnackBar(error);
      },
    });
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, null, {
      duration: 2000,
    });
  }

  openProgressBar() {
    this._snackBar.openFromTemplate(this.progressBar);
  }

  dismissProgressBar() {
    setTimeout(() => {
      this._snackBar.dismiss();
    }, 1000);
  }

  showPasswordField(): void {
    const form = this.userId ? this.editForm : this.createForm;
    this.passwordField = !this.passwordField;
    if (this.passwordField) {
      form.addControl('password', this.fb.control('', [
        Validators.required,
        Validators.minLength(6),
      ]));
    } else {
      form.removeControl('password');
    }
  }

}
