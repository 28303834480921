import {
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  FormBuilder,
  Validators,
} from '@angular/forms';

import {
  AdminSetting,
  AdminSettingsService,
  AdminSettingsType,
  FormValidator,
  ValidationService,
} from '../../../../core';
import { BaseSettingComponent } from '../base-setting.component';

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['../settings.component.scss'],
})
export class SettingComponent extends BaseSettingComponent implements OnInit {

  @Input() override setting: AdminSetting;
  fieldName = 'value';

  constructor(
    validationService: ValidationService,
    settingsService: AdminSettingsService,
    private fb: FormBuilder,
  ) {
    super(settingsService, validationService);
    this.form = this.fb.group({
      value: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.setFieldType();
    this.populateForm();
    setTimeout((): void => {
      this.validationService.addListeners();
      this.onFormValueChange(this.form);
    }, 500);
  }

  populateForm(): void {
    this.form.get(this.fieldName).patchValue(this.setting.value.data);

    const fieldsParams: FormValidator = {};
    if (this.setting.type === AdminSettingsType.string) {
      fieldsParams[this.fieldName] = {
        mode: 'change',
        validators: [this.validationService.number()],
      };
    }
    this.validationService.init(this.form, fieldsParams);
  }

  getFormValue(): { name: string, value: { data: string | number | boolean } } {
    return {
      name: this.setting.name,
      value: { data: this.form.get(this.fieldName).value },
    };
  }

  onError(err): void {
    let message = err.data['value.data'][0];
    message = message.replace('.data', '');
    this.form.get(this.fieldName).setErrors({ serverError: { message: message } });
  }

}
