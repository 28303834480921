<div class="page-container">
  <div class="row mb-3">
    <div class="col-auto">
      <div class="d-flex flex-wrap justify-content-between align-items-center gap-1">
        <div class="gap-1 d-flex flex-wrap align-items-center">
          <div class="row d-flex align-items-center">
            <div class="col-md-4 col-12 my-2 my-md-0">
              <mat-form-field appearance="fill" class="no-hint-input w-100">
                <mat-label>{{ 'filters.role' | translate }}</mat-label>
                <mat-select [(ngModel)]="ajaxParams.filter.role" (selectionChange)="applyFilter()">
                  @for (role of roles; track role) {
                    <mat-option [value]="role">
                      {{ userRole(role) }}
                    </mat-option>
                  }
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-8 col-12 my-2 my-md-0">
              <mat-form-field class="no-hint-input w-100">
                <mat-label>{{ 'filters.firstNameLastNameEmail' | translate }}</mat-label>
                <input #queryInput matInput [(ngModel)]="ajaxParams.filter.q">
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <mat-radio-group class="d-flex" [(ngModel)]="ajaxParams.filter.trashed" (ngModelChange)="applyFilter()">
                @for (status of statusFilter; track status) {
                  <mat-radio-button [value]="status">
                    {{ 'enum.userStatusFilter.' + status | translate }}
                  </mat-radio-button>
                }
              </mat-radio-group>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col d-flex align-items-center justify-content-end gap-1 mt-md-3 mt-lg-0">
      <button class="action-button" mat-flat-button color="primary" type="button" (click)="clearFilters()">
        {{ 'actions.clear' | translate }}
        <mat-icon>close</mat-icon>
      </button>
      <button class="action-button" mat-flat-button color="primary" type="button" routerLink="/users/add">
        {{ 'actions.create' | translate }}
        <mat-icon>person_add</mat-icon>
      </button>
    </div>
  </div>

  <div>
    @if (datasource.loading$ | async) {
      <div class="spinner">
        <mat-progress-spinner color="primary" mode="indeterminate" diameter="50">
        </mat-progress-spinner>
      </div>
    }

    <div class="table-container">
      <table mat-table #sort [dataSource]="datasource" aria-label="users" matSort>
        <ng-container matColumnDef="avatar">
          <th class="w-5" scope="row" mat-header-cell *matHeaderCellDef>
            {{ 'form.avatar.label' | translate }}
          </th>
          <td mat-cell *matCellDef="let user">
            <div class="avatar">
              <app-avatar class="d-flex" [user]="user" [width]="40" [preset]="avatarSize"></app-avatar>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="first_name">
          <th class="w-25" scope="row" mat-header-cell mat-sort-header *matHeaderCellDef>
            {{ 'form.firstName.label' | translate }}
          </th>
          <td mat-cell *matCellDef="let user"> {{ user.first_name }}</td>
        </ng-container>
        <ng-container matColumnDef="last_name">
          <th class="w-25" scope="row" mat-header-cell mat-sort-header *matHeaderCellDef>
            {{ 'form.lastName.label' | translate }}
          </th>
          <td mat-cell *matCellDef="let user"> {{ user.last_name }}</td>
        </ng-container>
        <ng-container matColumnDef="email">
          <th class="w-25" scope="row" mat-header-cell mat-sort-header *matHeaderCellDef>
            {{ 'form.email.label' | translate }}
          </th>
          <td mat-cell *matCellDef="let user"> {{ user.email }}</td>
        </ng-container>
        <ng-container matColumnDef="role">
          <th class="w-10" scope="row" mat-header-cell mat-sort-header *matHeaderCellDef>
            {{ 'form.role.label' | translate }}
          </th>
          <td mat-cell *matCellDef="let user">
            {{ userRole(user.role) }}
          </td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th class="w-10" scope="row" mat-header-cell mat-sort-header *matHeaderCellDef>
            {{ 'form.status.label' | translate }}
          </th>
          <td mat-cell *matCellDef="let user">
            <div class="status-badge d-flex justify-content-center" [ngClass]="{
                            'created': user.status === statuses.CREATED,
                            'invited': user.status === statuses.INVITED,
                            'confirmed': user.status === statuses.CONFIRMED,
                            'deleted': user.status === statuses.DELETED
                     }">
              {{ userStatus(user) }}
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="action">
          <th class="w-15 text-right" scope="row" mat-header-cell *matHeaderCellDef disabled>
            {{ 'actions.action' | translate }}
          </th>
          <td mat-cell *matCellDef="let user" class="text-right">
            <div class="d-flex align-items-center justify-content-end">
              <button mat-icon-button color="accent" type="button" routerLink="/users/{{user.id}}">
                <mat-icon class="user-view-icon">remove_red_eye</mat-icon>
              </button>
              <button (click)="user.deleted_at ? openConfirmModal(user, 'restore') : openConfirmModal(user, 'delete')"
                      mat-icon-button
                      type="button"
                      class="ms-2">
                @if (user.deleted_at) {
                  <mat-icon>restore_from_trash</mat-icon>
                }
                @if (!user.deleted_at) {
                  <mat-icon class="user-delete-icon">delete</mat-icon>
                }
              </button>
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
    <mat-paginator #pagination
                   [pageSizeOptions]="pageSizeOptions"
                   [length]="datasource.total$ | async">
    </mat-paginator>
  </div>
</div>
