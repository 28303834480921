import {
  ModuleWithProviders,
  NgModule,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatRadioModule } from '@angular/material/radio';

import { TranslateModule } from '@ngx-translate/core';
import { NgxChartsModule } from '@swimlane/ngx-charts';

import { AdminRoutingModule } from './admin-routing.module';
import {
  AdminSidebarComponent,
  ArraySettingComponent,
  SettingComponent,
} from './components';
import { AdminLayoutComponent } from './layout/admin-layout.component';
import {
  AdminDashboardComponent,
  AdminSettingsComponent,
  AdminUsersComponent,
  AdminUserViewComponent,
} from './pages';

import { SharedModule } from '../shared/shared.module';

const APP_COMPONENTS = [
  AdminLayoutComponent,
  AdminSidebarComponent,
  AdminDashboardComponent,
  AdminUsersComponent,
  AdminUserViewComponent,
  AdminSettingsComponent,
  SettingComponent,
  ArraySettingComponent,
];

@NgModule({
  imports: [
    SharedModule.forRoot(),
    AdminRoutingModule,
    ReactiveFormsModule,
    TranslateModule,
    MatCardModule,
    MatRadioModule,
    NgxChartsModule,
  ],
  declarations: [
    ...APP_COMPONENTS,
  ],
  exports: [
    ...APP_COMPONENTS,
  ],
})
export class AdminModule {
  static forRoot(): ModuleWithProviders<AdminModule> {
    return {
      ngModule: AdminModule,
    };
  }
}
