<form [formGroup]="form">
  <div class="d-flex justify-content-between px-3">
    @if (type !== 'boolean') {
      <mat-form-field appearance="fill">
        <input matInput [type]="type" formControlName="value">
        @if (form.get(fieldName)?.errors) {
          <mat-error>{{ parseError(form, fieldName) }}</mat-error>
        }
      </mat-form-field>
    } @else {
      <mat-checkbox formControlName="value">
      </mat-checkbox>
      @if (form.get(fieldName)?.errors) {
        <mat-error>{{ parseError(form, fieldName) }}</mat-error>
      }
    }
    <button mat-flat-button color="primary" type="button"
            [disabled]="isSubmitting || !changed || form.invalid" (click)="save()"
            class="form-button">
      {{ 'actions.save' | translate }}
    </button>
  </div>
</form>
