@if (loading) {
  <div class="spinner">
    <mat-progress-spinner color="primary" mode="indeterminate" diameter="50">
    </mat-progress-spinner>
  </div>
} @else {
  <div class="mx-3 mb-3">
    <div class="row">
      <div class="col-12">
        <mat-card>
          <mat-card-content>
            <div #numberCardContainerRef class="d-flex justify-content-center">
              <ngx-charts-number-card
                [view]="[numberCardContainerRef.offsetWidth, getCardsHeight(numberCardContainerRef.offsetWidth)]"
                [results]="socialChart.data"
                [cardColor]="socialChart.options.cardColor"
              >
              </ngx-charts-number-card>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-4 col-12">
        <mat-card>
          <mat-card-header class="mb-1 d-flex justify-content-center">
            <strong>{{ 'charts.users.label' | translate }}</strong>
          </mat-card-header>
          <mat-card-content>
            <div #pieChartContainerRef>
              <ngx-charts-pie-chart
                [view]="[pieChartContainerRef.offsetWidth, 400]"
                [results]="usersChart.data"
                [gradient]="usersChart.options.gradient"
                [legend]="usersChart.options.showLegend"
                [legendPosition]="usersChart.options.legendPosition"
                [labels]="usersChart.options.showLabels"
                [doughnut]="usersChart.options.isDoughnut"
              >
              </ngx-charts-pie-chart>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="col-md-8 col-12 mt-3 mt-md-0">
        <mat-card>
          <mat-card-header class="mb-1 d-flex justify-content-center">
            <strong>{{ 'charts.registrations.label' | translate }}</strong>
          </mat-card-header>
          <mat-card-content>
            <div #lineContainerRef>
              <ngx-charts-line-chart
                [view]="[lineContainerRef.offsetWidth, 400]"
                [legend]="registrationChart.options.legend"
                [legendPosition]="registrationChart.options.legendPosition"
                [xAxis]="registrationChart.options.xAxis"
                [yAxis]="registrationChart.options.yAxis"
                [animations]="registrationChart.options.animations"
                [results]="registrationChart.data"
                [tooltipDisabled]="tooltipDisabled"
              >
              </ngx-charts-line-chart>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
}


<div class="mx-3 mb-3">
  <div class="row my-3">
    <div class="col-12">
      <mat-card>
        <mat-card-header>
          <h2>
            <strong>{{ 'filters.actionLogs.label' | translate }}</strong>
          </h2>
        </mat-card-header>
        <mat-card-content>
          <app-action-logs></app-action-logs>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
