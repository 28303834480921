import { Component } from '@angular/core';

@Component({
  selector: 'app-admin-sidebar',
  template: '<app-sidebar [menu]="menu"></app-sidebar>',
  styleUrls: ['../../../shared/components/sidebar/sidebar.component.scss'],
})

export class AdminSidebarComponent {
  menu = [
    {
      id: 'dashboard',
      title: 'pages.dashboard',
      link: '/dashboard',
      icon: 'dashboard',
    },
    {
      id: 'actionLogs',
      title: 'pages.actionLogs',
      link: '/action-logs',
      icon: 'list_alt',
    },
    {
      id: 'users',
      title: 'pages.users.list',
      link: '/users',
      icon: 'person',
    },
    {
      id: 'teams',
      title: 'pages.teams.list',
      link: '/teams',
      icon: 'group',
    },
    {
      id: 'projects',
      title: 'pages.projects.list',
      link: '/projects',
      icon: 'work',
    },
    {
      id: 'tickets',
      title: 'pages.tickets.list',
      link: '/tickets',
      icon: 'assignment',
    },
    {
      id: 'settings',
      title: 'pages.settings',
      link: '/settings',
      icon: 'settings',
    },
  ];
}
